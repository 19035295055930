const $ = require('jquery'); // requireでjQueryの読み込み
$(function() {
	const _headerHeight = $(".header-wrap").height();
	$("main").css("padding-top",_headerHeight);
	$(".header-nav-button").on("click", function() {
		if ($(this).hasClass("active")) {
			$(this).removeClass("active");
			$(".header-nav-wrap")
				.addClass("close")
				.removeClass("open");
			} else {
				$(this).addClass("active");
				$(".header-nav-wrap")
					.addClass("open")
					.removeClass("close");
		}
	});
});
$(function(){
  var headerHeight = $('header').outerHeight(); // ヘッダーについているID、クラス名など、余白を開けたい場合は + 10を追記する。
  var urlHash = location.hash; // ハッシュ値があればページ内スクロール
  if(urlHash) { // 外部リンクからのクリック時
    $('body,html').stop().scrollTop(0); // スクロールを0に戻す
    setTimeout(function(){ // ロード時の処理を待ち、時間差でスクロール実行
      var target = $(urlHash);
      var position = target.offset().top - headerHeight;
      $('body,html').stop().animate({scrollTop:position}, 500); // スクロール速度ミリ秒
    }, 100);
  }
  $('a[href^="#"]').on("click", function(){ // 通常のクリック時
		console.log("clc");
		$(".header-nav-button").removeClass("active");
		$(".header-nav-wrap").addClass("close").removeClass("open");
    var href= $(this).attr("href"); // ページ内リンク先を取得
    var target = $(href);
    var position = target.offset().top - headerHeight;
    $('body,html').stop().animate({scrollTop:position}, 500); // スクロール速度ミリ秒
    return false; // #付与なし、付与したい場合は、true
  });
});
$(window).on("resize",function(){
	const _headerHeight = $(".header-wrap").height();
	$("main").css("padding-top",_headerHeight);
});
